<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'app',
  async beforeCreate() {
    const token = await window.localStorage.getItem("token");
    this.$store.dispatch('setToken', token);
  }
}
</script>

<style lang="scss">


:root{
  --primary-colour: #fff;
  --secondary-colour: #D5D8E1;
  --action-colour: #3bafda;
  --text-colour: #000;
  --secondary-text-colour: #fff;
  --warning-colour: #f44336;
  --banner-colour: #2e2f35;
  --border-colour: #ecf2f8;
  --hover-colour: #ededed;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Lato';
  color: var(--text-colour);
}

form {
  font-size: 1em;
  label { display: block; font-size: 0.8em; font-weight: bold; margin-bottom: 0.5rem; margin-top: 0.5rem;}
  input[type=text], input[type=file], input[type=number], textarea {
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: 1px solid #ccc;
    margin-bottom: 0.5rem;
    width: calc(100% - 2rem);
  }
}

button {
  padding: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  background-color: var(--action-colour);
  font-size: 1rem;
  color: var(--secondary-text-colour);
  font-family: 'Lato';
  margin-left: 1rem;
  display: inline-block !important;
  cursor: pointer;
  &:focus {outline:0;}
}

button.primary {
  background-color: var(--action-colour) !important;
}

button.persist {
  background-color: #65da3b !important;
}

button.warning {
  background-color: #ff9700 !important;
}


button.danger {
  background-color: #ff003b !important;
}

button.action {
  padding: 0.7rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  background-color: #ddd;
  margin: 0.5rem;
  width: 200px;
  display: inline-block !important;
}

.toggle-handle { height: 48px !important; width: 48px !important; }

.ql-container{
    background-color: var(--primary-colour);
}
.ql-toolbar{
    background-color: var(--secondary-text-colour);
}


</style>
