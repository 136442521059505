<template>
  <Unauthenticated>
    <div class="top-margin">

      <form v-on:submit.prevent="login">
        <img src="/logo.png" />
        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>

        <div :key="station" v-for="station in stations">
          <button @click="choose(station)" type="submit" value="Log In" class="w-100">{{ station.name }}</button>
        </div>

        <a class="other" href="/password">Sign out</a>

      </form>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from '@/components/_layouts/Unauthenticated'

export default {
  name: "ChooseStation",
  components: { Unauthenticated },
  data() {
    return {
      errors: {},
      stations: []
    }
  },
  async created() {
    const stationJson = await window.localStorage.getItem("stations");
    const currSite = await localStorage.getItem('site');
    this.stations = JSON.parse(stationJson).filter(station => station.site == currSite);
    if (this.stations.length == 1) {
      this.choose(this.stations[0]);
    }
  },
  methods: {
    choose(station) {
      console.log(station);
      window.localStorage.setItem("station", station.name);
      this.$router.push('/store')
    }
  }
}
</script>

<style scoped lang="scss">

form {
  border-radius: 0.5rem;
  max-width: 400px;
  padding: 2rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  font-family: 'lato';
  img { width: 100%; }

  button {
    margin-bottom: 1rem;
    padding: 1rem;
    width: 100%;
    background-color: var(--action-colour);
    font-weight: bold;
    text-transform: capitalize;
    margin-left: 0px !important;
  }

  button { font-size: 1rem; font-family: 'Architects Daughter'; }

}



.other {
  display: block;
  text-align: right;
  font-size: 1em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 0em;
    max-width: none !important;

  }
}

</style>
