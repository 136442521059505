<template>
  <Unauthenticated>
    <div class="top-margin">

      <form v-on:submit.prevent="login">
        <img src="/logo-t.png" />
        <input name="email" v-model="email" placeholder="Email Address" autocomplete="username"/>
        <div class="error" v-if="errors && errors.email">Email Address {{ errors.email.join(", ") }}</div>

        <input type="password" name="current-password" v-model="password" placeholder="Password"/>
        <div class="error" v-if="errors && errors.password">Password {{ errors.password.join(", ") }}</div>

        <div class="error" v-if="errors && errors.all">{{ errors.all }}</div>
        <button type="submit" value="Log In" class="primary w-100">Log In</button>

        <a class="password" href="/password">Forgot password?</a>
      </form>
    </div>
  </Unauthenticated>
</template>

<script>
import Unauthenticated from '@/components/_layouts/Unauthenticated'
import {api} from "@/helpers/api";

export default {
  name: "Login",
  components: { Unauthenticated },
  data() {
    return {
      errors: {},
      email: null,
      password: null
    }
  },
  methods: {
    async login() {
      this.errors = {};
      try {
        let result = await api.login(this.email, this.password);
        window.localStorage.setItem("token", result.jwt_token);

        /* set the global JWT token */
        this.$store.dispatch('setToken', result.jwt_token)

        const company = await api.get('/api/company/get_pos/"' + result.company + '"');
        window.localStorage.setItem("company", result.company);
        window.localStorage.setItem("sites", JSON.stringify(company.rows[0].value.sites));
        window.localStorage.setItem("stations", JSON.stringify(company.rows[0].value.stations));
        window.localStorage.setItem("theme", JSON.stringify(company.rows[0].value.theme));
        api.loadTheme(company.rows[0].value.theme);
        this.$.appContext.config.globalProperties.token = result.jwt_token;

        this.$router.push({ path: '/choose' });
      }
      catch(error) {
        Object.keys(error).map(code => {
          this.errors[code] = [error[code]];
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

form {
  border-radius: 0.5rem;
  max-width: 400px;
  padding: 2rem;
  margin: 0px auto;
  margin-top: 1rem;
  position: relative;
  background-color: var(--secondary-colour);
  font-size: 1em;
  img { width: 100%; }

  //a:v
  button {
    margin-bottom: 1rem;
    margin-lefT: 0px !important;
    padding: 1rem;
    width: 100%;
    background-color: lavender;
    font-weight: bold;
    border-radius: 0.25rem;
    background-color: var(--action-colour);
    color: var(--primary-colour);
  }

  input, button { font-size: 1rem; border: none;   }
  input { font-size: 1rem; box-sizing: border-box; padding: 1em; width: 100%;    }

  p.or { text-align: center; }

  .btn-outline-info:hover { background-color: inherit; }
}

form div.error {
  display: block;
  margin-bottom: 1em;
  color: #ff8197;
}

form input {
  position: relative;
  margin-bottom: 1rem;
}

form input, form button {
  display: block;
}

.password {
  display: block;
  text-align: right;
  font-size: 0.8em;
  margin-top: 10px;
}

@media screen and (max-width: 840px) {
  form {
    border: none;
    padding: 2em;
    max-width: none !important;

  }
}

</style>
