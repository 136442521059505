import App from './App.vue'
//import Vouchers from './components/Vouchers/Vouchers.vue'
//import Payments from './components/Payments/Payments.vue'
import Login from './components/Auth/Login.vue'
import ChooseStore from './components/Auth/ChooseStore.vue'
import ChooseStation from './components/Auth/ChooseStation.vue'
import {createStore} from 'vuex';
import cart from './store/cart.js';
import token from './store/token.js';
import {createApp} from 'vue';
import {api} from "@/helpers/api"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone, faEdit, faPlus, faSave, faTrash, faBars, faSort, faMinusCircle, faPlusCircle, faChevronDown, faChevronUp, faGripHorizontal, faGripLines, faCircleXmark, faSquareCaretDown, faPencil, faGear, faCamera, faBackward, faCodeCompare, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faEdit, faPlus, faSave, faTrash, faBars, faSort, faMinusCircle, faPlusCircle, faChevronDown, faChevronUp, faGripHorizontal, faGripLines, faCircleXmark, faSquareCaretDown, faPencil, faGear, faCamera, faBackward, faCodeCompare, faShoppingCart);

import {createRouter, createWebHashHistory} from 'vue-router'
//import loggingPlugin from "@/plugins/loggingPlugin";
//import { createLogger } from 'vuex'

//import Datepicker from '@vuepic/vue-datepicker';
//import '@vuepic/vue-datepicker/dist/main.css'
const Orders = () => import('./components/Orders/Orders.vue')
const Store = () => import('./components/Store/Store.vue')
const Cart = () => import('./components/Store/Cart.vue')
const Totals = () => import('./components/Totals/Totals.vue')
const Settings = () => import('./components/Settings/Settings.vue')
const Categories = () => import('./components/Categories/Categories.vue')
const Category = () => import('./components/Categories/Category.vue')
const Modifiers = () => import('./components/Modifiers/Modifiers.vue')
const ModifierSet = () => import('./components/Modifiers/ModifierSet.vue')

const store = createStore({
  modules: { token: token, cart: cart },
  //plugins: [createLogger()];
})


//const Cart = () => import('./components/Store/Cart.vue');

const router = new createRouter({
    //history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes: [
        {path: '/', name: 'Home', component: Store, meta: {requiresAuth: true} },
        {path: '/login', name: 'Login', component: Login},
        {path: '/choose', name: 'Choose', component: ChooseStore, meta: {requiresAuth: true} },
        {path: '/station', name: 'Station', component: ChooseStation, meta: {requiresAuth: true} },
        {path: '/cart', component: Cart, meta: {requiresAuth: true}},
        {path: '/store', component: Store, meta: {requiresAuth: true}},
        {path: '/store/:id', component: Store, meta: {requiresAuth: false}},
        {path: '/modifiers', component: Modifiers, meta: {requiresAuth: true}},
        {path: '/modifiers/:set', component: ModifierSet, meta: {requiresAuth: true}},
        {path: '/products', component: Categories, meta: {requiresAuth: true}},
        {path: '/products/:category', component: Category, meta: {requiresAuth: true}},
        {path: '/orders', component: Orders, meta: {requiresAuth: true}},
        //{path: '/vouchers', component: Vouchers, meta: {requiresAuth: true}},
        //{path: '/payments', component: Payments, meta: {requiresAuth: true}},
        {path: '/settings', component: Settings, meta: {requiresAuth: true}},
        {path: '/totals', component: Totals, meta: {requiresAuth: true}},
        //{path: '/discounts', component: Discounts, meta: {requiresAuth: true}},
    ]
})

export default store;


const isAuthenticated = async() => {
    return store.getters.token ? true : false;
}

const isWebStore = async(id) => {
  await store.commit("setWebStoreMutation",true);
  await api.getSite(id);
}

router.beforeEach(async (to, from, next) => {
  let url = window.location.host.split(".");

  if (to.params.id) {
      await isWebStore(to.params.id);
  } else if (url[1] == "web") {
      await isWebStore(url[0]);
  }else{
    await store.commit("setWebStoreMutation",false);
    //api.loadTheme(JSON.parse(window.localStorage.getItem("theme")));
  }

  const authenticated = await isAuthenticated();
  if ( to.meta.requiresAuth ) {
    if ( authenticated )
      return next()

    return next({name: 'Login'});
  }
  
  return next();
  
})

const app = createApp(App)
app.use(router).use(store).component("font-awesome-icon", FontAwesomeIcon).mount('#app');
//app.component('Datepicker', Datepicker);
//app.use(loggingPlugin);
