import sha1 from "sha1"
import {api} from "@/helpers/api";

const state = {
  added: [],
  payments: [],
  all: [],
  categories: [],
  prod_in_cat: [],
  modifier_sets: [],
  discounts: [],
  history: [],
  historyPos: 0,
  loading: false,
  siteSettings: {},
  localSettings: [],
  cartOrder: null,
  orderName: false,
  orderNotes: false,
  orderDelivery: false,
  socketConnected: false,
  tableOrGrid: true,
  webStore: false,
  futurePickup: false,
  stripePaymentIntent: false,
  stripeTotal: 0,
}

const getters = {
    tableOrGrid: state => state.tableOrGrid,
    webStore: state => state.webStore,
    futurePickup: state => state.futurePickup,
    socketConnected: state => state.socketConnected,
    orderName: state => state.orderName,
    orderNotes: state => state.orderNotes,
    orderDelivery: state => state.orderDelivery,
    siteSettings: state => state.siteSettings,
    localSettings: state => state.localSettings,
    eftposSettings: state => {
        return state.localSettings.map(station => {
            return ((station.name == window.localStorage.getItem("station") ? station.eftpos : false));
        }).filter(Boolean);
    },
    loading: state => state.loading,
    allProducts: state => state.all, // would need action/mutation if data fetched async
    discounts: state => state.discounts,
    allCategories: state => state.categories,
    cartOrder: state => state.cartOrder,
    categories: state => {
        return state.categories.filter(category => {
            return ((category.key && 'active' in category.key && category.key.active === true) || (category.key && !('active' in category.key)));
        });
    },
    prod_in_cat: state => state.prod_in_cat,
    modifier_sets: state => state.modifier_sets,
    getNumberOfProducts: state => (state.all) ? state.all.length : 0,
    cartPayments: state => {
        return state.payments.map((payment) => {
            return payment;
        });
    },
    history: state => state.history,
    cartProducts: state => {

        /* this code is really questionable */
        /* the es6 proxies work oddly - I should be able to pass them through and access them via getters in the view */
        /* but it doesn't seem to work */

        var findProductCategory = (product) => {
            //var lowercaseProduct = product.toLowerCase().replace(/ /g, '-');
            var foundCategory = state.categories.find((category) => {
                return category.key.products && category.key.products.find(p => {
                    //console.log(lowercaseProduct);
                    
                    return product === p;
                });
            })
            //console.log(foundCategory.key.name);
            if (foundCategory) {
                return foundCategory.key.name;
            } else {
		return "Unknown";
            }
        };

        let lineItems = state.added.map(({id, complete, hash, modifiers, quantity}) => {

            const product = state.all.find(p => p.id === id);
            let modifier_obj = {}
            let modifier_total = 0;

            var clonedModifiers = Object.assign({}, modifiers);
            delete clonedModifiers.value;

            Object.keys(clonedModifiers).map(key => {
                modifier_obj[key] = clonedModifiers[key].map(i => i);
                modifier_total += clonedModifiers[key].reduce((total, m) => {
                    return total + m.price
                }, 0);
            });
            //console.log(product.value._id.slice(8));
            //console.log(state.categories);
            if (product) {
                console.log(product)
                return {
                    id: product.value._id,
                    hash: hash,
                    name: product.value.name,
                    complete: complete,
                    autocomplete: product.value.autocomplete,
                    modifiers: modifier_obj,
                    modifier_list: Object.values(modifier_obj),
                    price: product.value.price + modifier_total,
                    category: findProductCategory(product.value._id.slice(8)),
                    quantity
                }
            } else {
              console.log("product not found", state.all);
            }
        })

        return lineItems;
    },
    modifiers: (state) => (set) => {
        return state.modifier_sets.map(modifier => {
            return set.includes(modifier.key) ? modifier.value : null
        }).filter(Boolean);
    },
    active_products: state => {
        return state.prod_in_cat.filter((docs) => {
            if (docs.value.active == true) {
                return docs;
            }
        })
    },
    active_products_in_categories: (state) => (cat) => {
      let p = [];
      if (cat.key) {
        cat.key.products.forEach(function (prod) {
          let product = state.all.find(x => x.id == 'product:' + prod);
          if (product && product.value.active == true) {
            p.push(product)
          }
        })
      }
      return p
    },
    productInfo: (state) => (product) => {
        return state.all.find(p => {
            return 'product:' + product === p.id
        })
    },
    stripeTotal: state => state.stripeTotal,
}

// actions
const actions = {

    loading({commit}) {
        commit('loading');
    },

    async setSocketConnected({commit}, socketConnected) {
        commit('setSocketConnectedMutation', socketConnected)
    },

    async getAllData({dispatch}) {
        Promise.all([
          dispatch('loading'),
          dispatch('getProducts'),
          dispatch('getCategories'),
          dispatch('getProductsInCategories'),
          dispatch('getDiscounts'),
          dispatch('getModifierSets'),
          dispatch('getSettings'),
          dispatch('getHistory')
        ])
      dispatch('loading')
    },

    async getSettings({commit}) {
        if (!Object.keys(state.siteSettings).length) {
            const companyString = await localStorage.getItem("company");
            const siteString = await window.localStorage.getItem("site");
            const companyResults = await api.get('/api/company/get_pos/"' + companyString + '"');
            console.log(companyResults)
            const settings = companyResults.rows[0].value.settings
            commit('initialiseSiteSettingsMutation', settings[siteString])
        }

          const companyString = await localStorage.getItem("company");
          const site = await localStorage.getItem("site");
          const localStation = await localStorage.getItem("station");
          const pos_stations = await api.get('/api/company/get_pos/"' + companyString + '"');
          const stations = pos_stations.rows[0].value.stations;
          console.log(stations[0].name, site, localStation);
          const newLocal = stations.filter(station => (station.site == site && station.name == localStation));
          console.log(newLocal);
          // const stationName = await window.localStorage.getItem("station")
          // stations.forEach(el => console.log(el));
          commit('updateLocalSettingsMutation', newLocal)
        

    },

    async getHistory({commit}) {
        const history = await api.getUrl('/api/get_all_orders', '?limit=50&descending=true', true);
        commit('updateHistoryPosMutation', history.rows.length)
        commit('initialiseHistoryMutation', history.rows)
    },

    async appendHistory({commit}) {
        const history = await api.getUrl('/api/get_all_orders', '?limit=50&descending=true&skip=' + state.historyPos, true);
        commit('updateHistoryPosMutation', history.rows.length)
        //state.historyPos += 10;
        history.rows.forEach(row => {
            //console.log(row);
            commit('appendHistoryMutation', JSON.parse(JSON.stringify(row)))
        })
    },

    async prependHistoryNew({commit}, newOrder) {
        commit('updateHistoryPosMutation', 1)
        commit('prependHistoryMutation', JSON.parse(JSON.stringify(newOrder)))
    },

    async findAndUpdateHistoryDoc({commit}, order) {
        commit('findAndUpdateHistoryDocMutation', JSON.parse(JSON.stringify(order)))
    },

    async getProducts({commit}) {
      const products = await api.getUrl('/api/products/get', '?include_docs=false', true);
      commit('initialiseProductsMutation', products.rows)
      /***  if (!state.all.length) {
          const products = await api.getUrl('/api/products/get', '?include_docs=false', true);
          commit('initialiseProductsMutation', products.rows)
        }***/
    },

    async getCategories({commit}) {
      const categories = await api.getUrl('/api/categories/get', '?include_docs=false', true);
      commit('initialiseCategoriesMutation', categories.rows)
    },

    async getProductsInCategories({commit}) {
      const categories = await api.getUrl('/api/products_in_categories/get', '?include_docs=false', true);
      commit('initialiseProdInCatMutation', categories.rows)
    },

    async getDiscounts({commit}) {
      const discounts = await api.getUrl('/api/discounts/get', '?include_docs=false', true);
      commit('initialiseDiscountsMutation', discounts.rows)
    },

    async getModifierSets({commit}) {
      const sets = await api.getUrl('/api/modifiersets/get', '?include_docs=false', true);
      commit('initialiseModifierSetsMutation', sets.rows)
    },

    addToCart({commit}, product) {
        commit('addToCartMutation', {
            id: product.id,
            modifiers: product.modifiers,
            price: product.price,
            hash: product.hash
        })
    },

    changeLineItemQuantity({commit}, hash) {
        commit('changeLineItemQuantityMutation', {
            productHash: hash.productHash,
            delta: hash.delta
        });
    },

    removeFromCart({commit}, product) {
        commit('removeFromCartMutation', {
            id: product
        })
    },

    clearCart({commit}) {
        commit('clearCartMutation');
    },

    clearPayments({commit}) {
        commit('clearPaymentsMutation');
    },

  deletePayment({commit}, paymentIndex) {
    commit('deletePaymentMutation', paymentIndex);
  },
  deleteEftposPayment({commit}) {
    const payments = state.payments.map(payment => payment.type);
    commit('deletePaymentMutation', payments.lastIndexOf('eftpos'));
  },

    updateModifierSet({commit}, ms) {
        commit('updateModifierSetMutation', ms)
    },

    deleteModifierSet({commit}, data) {
        commit('deleteModifierSetMutation', data);
    },

    deleteDiscount({commit}, data) {
        commit('deleteDiscountMutation', data);
    },


    updateDiscount({commit}, discount) {
        commit('updateDiscountMutation', discount)
    },

    updateSettings({commit}, settings) {
        commit('updateSiteSettingsMutation', settings[0])
        commit('updateLocalSettingsMutation', settings[1])
    },

    updateProduct({commit}, data) {
        commit('updateProductMutation', data)
    },

    updateCategory({commit}, data) {
        commit('updateCategoryMutation', data)
    },

    addCategory({commit}, category) {
        commit('addCategoryMutation', category)
    },

    deleteCategory({commit}, data) {
        commit('deleteCategoryMutation', data);
    },

    initialiseCartFromLocalStorage({commit}, cart) {
        commit('initialiseCartFromLocalStorageMutation', cart)
    },

    initialiseCartFromHistory({commit}, cart) {
        commit('initialiseCartFromHistoryMutation', cart)
    },

    addPayment({commit}, obj) {
        commit('addPaymentMutation', obj)
    },

  setStripePaymentIntent({commit}, intent) {
    commit('setStripePaymentIntentMutation', intent)
  },
  setWebStore({commit}, status){
    commit('setWebStoreMutation', status)
  },
  setFuturePickup({commit}, status){
    commit('setFuturePickupMutation', status)
  },
  setStripeTotal({commit}, total){
    commit('setStripeTotalMutation', total)
  }
}

// mutations
const mutations = {

    gridOr(state){
        state.tableOrGrid = !state.tableOrGrid;
    },

    loading(state) {
        state.loading = !state.loading;
    },

    addToCartMutation(state, product) {
        let hash;

        var clonedModifiers = Object.assign({}, product.modifiers);
        delete clonedModifiers.value;

        if (!product.hash) {
          hash = sha1(product.id + JSON.stringify(Object.assign({}, product.modifiers)));
        } else {
            hash = product.hash;
        }

        /* a lineItem is a single product with it's combination of modifiers on the current cart */
        const lineItem = state.added.find(lineItem => lineItem.hash === hash)

        if (!lineItem) {
            state.added.push({
                hash: hash,
                id: product.id,
                complete: false,
                modifiers: JSON.parse(JSON.stringify(Object.assign({}, product.modifiers))),
                price: product.price,
                quantity: 1
            });
        } else if (!product.hash) {
            lineItem.quantity++
        }
    },

    changeLineItemQuantityMutation(state, {productHash, delta}) {
        const lineItem = state.added.find(lineItem => lineItem.hash === productHash);
        lineItem.quantity = lineItem.quantity + delta;

        if (lineItem && delta < 0) {
            const index = state.added.findIndex(lineItem => lineItem.hash === productHash)

            if (lineItem.quantity < 1) {
                state.added.splice(index, 1)
            }
        }

    },

    setSocketConnectedMutation(state, value) {
        state.socketConnected = value;
    },

    initialiseProductsMutation(state, value) {
        //console.log(JSON.stringify(value));
        state.all = value;
    },

    initialiseHistoryMutation(state, value) {
        state.history = value;
    },

    updateHistoryPosMutation(state, value) {
        state.historyPos += value;
    },

    appendHistoryMutation(state, value) {
        state.history.push(value);
    },

  prependHistoryMutation(state, value) {
    console.log('new order');
        state.history.splice(0, 0, value);
    },

    findAndUpdateHistoryDocMutation(state, value) {
      // eslint-disable-next-line no-unused-vars
        let foundOrder = state.history.find(order => {
            return order.doc._id === value.doc._id
        })
        foundOrder.doc = value.doc
    },

    initialiseDiscountsMutation(state, value) {
        state.discounts = value;
    },

    initialiseSiteSettingsMutation(state, settings) {
        state.siteSettings = settings;
    },

    initialiseLocalSettingsMutation(state, settings) {
        state.localSettings = settings;
    },

    updateSiteSettingsMutation(state, siteSettings) {
        state.siteSettings = siteSettings;
    },

    async updateLocalSettingsMutation(state, localSettings) {
        state.localSettings = localSettings;
        window.localStorage.setItem('localSettings', JSON.stringify(state.localSettings));
    },

    updateProductMutation(state, product) {
        let prod = state.all.find(p => p.id === product._id);
        if (prod) {
            prod.doc = product;
            prod.value = product;
        } else {
            state.all.push({id: product._id, key: product._id, doc: product, value: product, images: []});
        }
    },

    updateDiscountMutation(state, discount) {
        let disc = state.discounts.find(d => d.id === discount._id);
        if (disc) {
            disc.doc = discount;
            disc.value = discount;
        } else {
            state.discounts.push({doc: discount, value: discount});

        }
        console.log(state.discounts.length);
    },


    initialiseCategoriesMutation(state, value) {
        state.categories = value;
    },

    addCategoryMutation(state, value) {
        state.categories.push(value);
    },

    updateCategoryMutation(state, category) {
        let cat = state.categories.find(c => c.id === category._id);
        console.log(cat);
        if (cat) {
            cat.doc = category;
            cat.key = category;
            cat.value = category;
        } else {
            state.categories.push({id: category._id, key: category, doc: category});
            state.categories = state.categories.sort((a, b) => {
                return a.id < b.id ? -1 : 1
            });
        }
    },

    initialiseProdInCatMutation(state, value) {
        state.prod_in_cat = value;
    },

    initialiseModifierSetsMutation(state, value) {
        state.modifier_sets = value;
    },

    updateModifierSetMutation(state, doc) {
        let foundSet = state.modifier_sets.find(modifier_set => modifier_set.id === doc._id);
        if (foundSet) {
            foundSet.doc = doc;
            foundSet.value = doc;
        } else {
            state.modifier_sets.push({doc: doc, value: doc, key: doc._id, id: doc._id});
            /* need to sort the sets again */
            state.modifier_sets = state.modifier_sets.sort((a, b) => {
                return a.id < b.id ? -1 : 1
            });
        }
    },

    deleteModifierSetMutation(state, modifierset) {
        state.modifier_sets = state.modifier_sets.filter(modifier_set => {
            return (modifier_set.id !== modifierset.id)
        });
    },

    deleteCategoryMutation(state, data) {
        state.categories = state.categories.filter(category => {
            return (category.id !== data.id)
        });
    },

    deleteDiscountMutation(state, data) {
        state.discounts = state.discounts.filter(discount => {
            return (discount.id !== data.id)
        });
    },

    initialiseCartFromLocalStorageMutation(state, value) {
        state.added = value.products;
        state.payments = value.payments;
    },

    initialiseCartFromHistoryMutation(state, value) {
        console.log(state, value);
        state.cartOrder = value;
        state.added = value.products;
        state.payments = value.payments;
        state.orderName = value.name;
        state.orderNotes = value.notes;
        state.orderDelivery = value.delivery;
        console.log('state name: ', value);
    },


    addPaymentMutation(state, obj) {
        state.payments.push({type: obj.type, value: obj.value});

    },

    clearCartMutation(state) {
        state.orderName = null;
        state.orderNotes = null;
        state.orderDelivery = null;
        state.payments = [];
        state.cartOrder = {};
        state.added = [];
        console.log('cleared');
    },

    clearPaymentsMutation(state) {
        state.payments = [];
    },

  deletePaymentMutation(state, paymentIndex) {
    state.payments.splice(paymentIndex, 1);
  },

  setStripePaymentIntentMutation(state, intent) {
    state.stripePaymentIntent = intent;
  },
  setWebStoreMutation(state, status){
    state.webStore = status;
  },
  setFuturePickupMutation(state, status){
    state.futurePickup = status;
  },
  setStripeTotalMutation(state, total){
    state.stripeTotal = total;
  }


}


const cartModule = {
    state,
    strict: true,
    getters,
    actions,
    mutations,
}

export default cartModule;
